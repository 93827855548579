import React from "react";
import { Container, Header, Table, Segment, Icon } from "semantic-ui-react";
import styled from "styled-components";
import Layout from "../components/Layout";
import SEO from "../components/seo";

const StyledTable = styled(Table)`
  border: 2px solid white !important;
`;

const HeaderCell = styled(Table.Cell)`
  background-color: ${props => (props.first ? "#730099" : "#f2711c")};
  color: white;
  text-align: center !important;
  border: 2px solid white;
`;

const DescribeCell = styled(Table.Cell)`
  background-color: #730099;
  color: white;
  text-align: center !important;
  border: 2px solid white !important;
`;
const TableCell = styled(Table.Cell)`
  background-color: #f9fafb;
  text-align: center !important;
  border: 2px solid white !important;
`;

const ProductLink = styled.a`
  color: white;
`;

const IndexPage = () => (
  <Layout activePage="services">
    <SEO title="Pakiety" />
    <Segment
      vertical
      style={{
        background: "none",
        margin: "0 0 0 0",
        minHeight: "100%",
      }}
    >
      <Container text>
        <Header
          as="h1"
          content="PAKIETY"
          style={{
            margin: "1em 0 1em 0",
            textAlign: "center",
          }}
        />
      </Container>
      <Container>
        <StyledTable>
          <Table.Header>
            <Table.Row>
              <HeaderCell first={1}>Pakiety</HeaderCell>
              <HeaderCell>
                <ProductLink href="https://medicover24.doradcainformatyczny.pl/products/5e09bbe61b888ce0ed467297">
                  Pojedyńcza interwencja
                </ProductLink>
              </HeaderCell>
              <HeaderCell>
                <ProductLink href="https://medicover24.doradcainformatyczny.pl/products/5e09bc3c1b888ce0ed46729a">
                  Pakiet SMART
                </ProductLink>
              </HeaderCell>
              <HeaderCell>
                <ProductLink href="https://medicover24.doradcainformatyczny.pl/products/5e09bc5d1b888ce0ed46729d">
                  Pakiet TWÓJ BIZNES
                </ProductLink>
              </HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <DescribeCell>Liczba interwencji</DescribeCell>
              <TableCell>1</TableCell>
              <TableCell>12</TableCell>
              <TableCell>24</TableCell>
            </Table.Row>
            <Table.Row>
              <DescribeCell>Liczba urządzeń objętych usługą</DescribeCell>
              <TableCell>Jedno urządzenie</TableCell>
              <TableCell colSpan="2">
                wszystkie urządzenia <br />
                <small
                  style={{
                    fontSize: "70%",
                  }}
                >
                  (komputer stacjonarny, laptop, notebook, netbook, ultrabook,
                  MacOS 10.5+, tablet, smartfon)
                </small>
              </TableCell>
            </Table.Row>
            <Table.Row>
              <DescribeCell>Okres ważności</DescribeCell>
              <TableCell>1 miesiąc</TableCell>
              <TableCell>6 miesięcy</TableCell>
              <TableCell>12 miesięcy</TableCell>
            </Table.Row>
            <Table.Row>
              <DescribeCell>Osoby uprawnione</DescribeCell>
              <TableCell>Klient, jego współmałżonek, oraz dzieci</TableCell>
              <TableCell colSpan="2">
                Klient, jego współmałżonek, dzieci, oraz uprawnieni pracownicy
              </TableCell>
            </Table.Row>
            <Table.Row>
              <DescribeCell>Cena za cały okres trwania usługi</DescribeCell>
              <TableCell>29 PLN</TableCell>
              <TableCell>84 PLN</TableCell>
              <TableCell>170 PLN</TableCell>
            </Table.Row>
          </Table.Body>
        </StyledTable>
      </Container>
    </Segment>
  </Layout>
);

export default IndexPage;
